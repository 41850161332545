import React from 'react';
import { useAuth } from 'react-oidc-context';
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate
} from 'react-router-dom';
import Login from '../Login/Login';
import MainMenu from '../MainMenu/MainMenu';
import MySheets from '../MySheets/MySheets';
import SheetViewer from '../SheetViewer/SheetViewer';
import './App.scss';
import Songs from '../SheetOverview/Songs';

const App = () => {
  const auth = useAuth();

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={auth.isAuthenticated ? <MainMenu /> : <Login />}>
          <Route index element={<Navigate to="/meine-noten" />} />
          <Route path="/meine-noten" element={<MySheets />} />
          <Route path="/uebersicht" element={<Songs />} />
          <Route
            path="/einzelstimmen/:partiturSheetId/:singleVoiceSheetId"
            element={<SheetViewer />}
          />
          <Route path="/partitur/:partiturSheetId" element={<SheetViewer />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
