import React, { FC, useState } from 'react';
import './MySheets.scss';
import { useAuth } from 'react-oidc-context';
import { Link } from 'react-router-dom';
import { useFetch } from 'usehooks-ts';
import { Searcher } from 'fast-fuzzy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

interface MySheetsProps {}

const MySheets: FC<MySheetsProps> = () => {
  const auth = useAuth();

  const mePlayerId = useFetch(
    `${process.env.REACT_APP_BACKEND_URL}/players/me`,
    {
      headers: {
        Authorization: `${auth.user.access_token}`
      }
    }
  ).data;

  const voices = useFetch<
    {
      nameSong: string;
      nameInstrument: string;
      partiturSheetId: string;
      singleVoiceSheetId: string;
      namePartitur?: string;
    }[]
  >(
    mePlayerId
      ? `${process.env.REACT_APP_BACKEND_URL}/players/${mePlayerId}/voices`
      : undefined,
    {
      headers: {
        Authorization: `${auth.user.access_token}`
      }
    }
  ).data;

  const [query, setQuery] = useState<string>();

  const filteredVoices = query
    ? new Searcher(voices, {
        keySelector: (voice) => (voice.namePartitur || '') + voice.nameSong
      }).search(query)
    : voices;

  return (
    <div className="MySheets" data-testid="MySheets">
      <input
        type="text"
        value={query}
        onInput={(e) => setQuery((e.target as HTMLInputElement).value)}
      />
      <div className="voices-wrapper">
        {filteredVoices
          ? filteredVoices.map((voice) => (
              <Link
                to={`/einzelstimmen/${voice.partiturSheetId}/${voice.singleVoiceSheetId}`}
                className="voice"
                key={voice.namePartitur || voice.nameSong}>
                <div className="info">
                  <h2>{voice.nameSong}</h2>
                  {voice.namePartitur && <h3>{voice.namePartitur}</h3>}
                  <p>{voice.nameInstrument}</p>
                </div>
                <div className="icon-wrapper">
                  <FontAwesomeIcon icon={faEye} />
                </div>
              </Link>
            ))
          : false}
      </div>
    </div>
  );
};

export default MySheets;
