import { faHeadphones } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import './InstrumentControls.scss';

interface InstrumentControlsProps {
  track: {
    name: string;
    gain: number;
    preservedGain: number;
    partOfSolo: boolean;
  };
  updateGain: (gain: number) => void;
  solo: (add: boolean) => void;
}

const InstrumentControls: FC<InstrumentControlsProps> = ({
  track,
  updateGain,
  solo
}) => {
  const [gain, setGain] = useState(track.preservedGain);
  const [isPartOfSolo, setIsPartOfSolo] = useState(track.partOfSolo);

  const handleGainChange = (e: ChangeEvent<HTMLInputElement>) => {
    const g = Number.parseFloat(e.target.value);
    updateGain(g); // Für Audio Änderung
    setGain(g); // Für visuelle Änderung
    // Warum?
    // setTracks(old => {
    //   old[i].gain = g;
    //   return old;
    // });
    // löst keinen Rerender aus, weil es immer noch das selbe Array ist.
    // Aus Performance-Gründen, wollen wir vermutlich auch keinen Rerender durch Update des tracks Arrays auslösen, weil das erzeugen von Instrumenten lange dauert.
    // Vielleicht könnte man ein neues Array erstellen und die Instrumente übertragen oder so etwas? Ist erst mal komplizierter als das hier.
  };

  const handleSoloChange = () => {
    solo(!isPartOfSolo);
    setIsPartOfSolo((old) => !old);
  };

  return (
    <div className="InstrumentControls" data-testid="InstrumentControls">
      <div className="slider-and-name">
        <div className="name">{track.name}</div>
        {/* step 0.4 wegen Benutzung mit Tastatur */}
        <input
          type="range"
          min="0"
          max="1"
          step="0.04"
          value={gain}
          onChange={handleGainChange}
        />
      </div>
      <button
        type="button"
        onClick={handleSoloChange}
        className={isPartOfSolo ? 'solo' : ''}>
        <FontAwesomeIcon icon={faHeadphones} />
      </button>
    </div>
  );
};

export default InstrumentControls;
