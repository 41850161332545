// Zoom und Pan wird hier selbst implementiert obwohl das Browser eigentlich schon können, weil natives Zoomen dazu führt, dass das PDF verpixelt angezeigt wird.

// todo:
// * add name to download
// * use opensheetmusicdisplay if MusicXML is available, otherwise fall back to PDF
// * add audio playback

import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useParams } from 'react-router-dom';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import './SheetViewer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileArrowDown,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
  faPrint
} from '@fortawesome/free-solid-svg-icons';
import printJS from 'print-js';
import ReactDOM from 'react-dom';
import Midi from '../Midi/Midi';

interface SheetViewerProps {}

const SheetViewer: FC<SheetViewerProps> = (props) => {
  const auth = useAuth();
  const { partiturSheetId } = useParams();
  let { singleVoiceSheetId } = useParams();
  const scoreType = singleVoiceSheetId ? 'single' : 'full';
  if (scoreType === 'full') singleVoiceSheetId = partiturSheetId;

  const [sheet, setSheet] = useState<ArrayBuffer>();
  const [sheetURL, setSheetURL] = useState<string>();
  const [numPages, setNumPages] = useState<number>(null);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);
  const [scale, setScale] = useState<number>(1);
  const [offset, setOffset] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0
  });
  const [fileName, setFileName] = useState<string>('Noten.pdf');
  const lastTouchLocation = useRef<{ x: number; y: number }>(null);
  const setDimensionsTimeout = useRef<number>(null);
  const resizeObserver = useRef<ResizeObserver>();
  const domRefCbTimeout = useRef<number>(null);
  const domRef = useRef<HTMLDivElement>();
  const domRefCb = useCallback((el: HTMLDivElement) => {
    domRef.current = el;
    if (!el) return;
    setDimensions();
    resizeObserver.current = new ResizeObserver(setDimensions);
    resizeObserver.current.observe(domRef.current);
  }, []);
  const contentRef = useRef<HTMLDivElement>();
  const contentRefCb = useCallback((el: HTMLDivElement) => {
    contentRef.current = el;
  }, []);

  const setDimensions = () => {
    // setHeight zu buffern verbessert resize-Performance, wenn das Fenster langsam aufgezogen wird, da sonst das PDF sehr häufig gerendert wird.
    if (setDimensionsTimeout.current) {
      clearTimeout(setDimensionsTimeout.current);
      setDimensionsTimeout.current = null;
    }
    setDimensionsTimeout.current = window.setTimeout(() => {
      if (!contentRef.current) return; // clearTimeout does not work reliably. This is easier to program...
      setHeight(contentRef.current.clientHeight);
      setWidth(contentRef.current.clientWidth);
      setDimensionsTimeout.current = null;
    }, 50);
  };

  const zoomIn = () => {
    setScale((old) => old * 1.3);
  };

  const zoomOut = () => {
    setScale((old) => Math.max(old / 1.3, 1));
  };

  useEffect(() => {
    setOffset((old) => boundedOffset(old));
  }, [scale]);

  const boundedOffset = (o: { x: number; y: number }) => {
    let pageWidth;
    let pageHeight;
    if (width * Math.SQRT2 < height) {
      pageWidth = width;
      // Die lange Seite von DIN-Papier ist Wurzel(2) mal so lang wie die kurze Seite.
      // Deshalb lässt sich DIN-Papier von großen Formaten auf Kleine falten.
      pageHeight = pageWidth * Math.SQRT2;
    } else {
      pageHeight = height;
      pageWidth = pageHeight / Math.SQRT2;
    }
    let minX;
    let minY;
    if (matchMedia('(pointer: fine)').matches) {
      minX = -Math.max(0, numPages * scale * pageWidth - width);
      minY = -Math.max(0, scale * pageHeight - height);
    } else {
      minX = -Math.max(0, scale * pageWidth - width);
      minY = -Math.max(0, numPages * scale * pageHeight - height);
    }
    return {
      x: Math.min(Math.max(minX, o.x), 0),
      y: Math.min(Math.max(minY, o.y), 0)
    };
  };

  const mousePan = (e: React.MouseEvent) => {
    if (e.buttons !== 1) return;
    setOffset((old) => {
      return boundedOffset({
        x: old.x + e.movementX,
        y: old.y + e.movementY
      });
    });
  };

  const touchStart = (e: React.TouchEvent) => {
    if (e.touches.length !== 1) return;
    lastTouchLocation.current = {
      x: e.touches[0].clientX,
      y: e.touches[0].clientY
    };
  };

  const touchPan = (e: React.TouchEvent) => {
    if (!lastTouchLocation.current) return;
    if (e.touches.length !== 1) return;
    const deltaX = e.touches[0].clientX - lastTouchLocation.current.x;
    const deltaY = e.touches[0].clientY - lastTouchLocation.current.y;
    lastTouchLocation.current = {
      x: e.touches[0].clientX,
      y: e.touches[0].clientY
    };
    setOffset((old) => {
      return boundedOffset({
        x: old.x + deltaX,
        y: old.y + deltaY
      });
    });
  };

  const touchEnd = (e: React.TouchEvent) => {
    lastTouchLocation.current = null;
  };

  const scroll = (e: React.WheelEvent) => {
    if (e.shiftKey) {
      setOffset((old) => {
        return boundedOffset({
          x: old.x,
          y: old.y - e.deltaY
        });
      });
    } else {
      setOffset((old) => {
        return boundedOffset({
          x: old.x - e.deltaY,
          y: old.y
        });
      });
    }
  };

  // Hole PDF und erstelle ObjectURL für Download
  useEffect(() => {
    (async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/sheets/${scoreType}/${singleVoiceSheetId}`,
        {
          headers: {
            Authorization: `${auth.user.access_token}`
          }
        }
      ).then((r) => r.arrayBuffer());
      setSheet(response);
      if (sheetURL) window.URL.revokeObjectURL(sheetURL);
      setSheetURL(window.URL.createObjectURL(new Blob([response])));
    })();
    return () => {
      window.URL.revokeObjectURL(sheetURL);
    };
  }, [singleVoiceSheetId]);

  // Bestimme Dateinamen für Download
  useEffect(() => {
    (async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/sheets/${scoreType}/${singleVoiceSheetId}/name`,
        {
          headers: {
            Authorization: `${auth.user.access_token}`
          }
        }
      ).then((r) => r.json());
      const name =
        scoreType === 'full'
          ? `${response.namePartitur} - Partitur.pdf`
          : `${response.namePartitur} - ${response.nameInstrument}.pdf`;
      setFileName(name);
    })();
  }, [singleVoiceSheetId]);

  // Räume Timeouts usw. auf
  useEffect(() => {
    return () => {
      clearTimeout(setDimensionsTimeout.current);
      clearTimeout(domRefCbTimeout.current);
      if (resizeObserver.current) {
        resizeObserver.current.disconnect();
        resizeObserver.current = null;
      }
    };
  }, []);

  const onLoadSuccess = (p: { numPages: number }) => {
    setNumPages(p.numPages);
  };

  if (!sheet) return null;

  return (
    <div ref={domRefCb} className="SheetViewer" data-testid="SheetViewer">
      {/* Interaktionsleiste kriegt Styles von MainMenu.scss */}
      {ReactDOM.createPortal(
        <>
          <Midi partiturSheetId={partiturSheetId} />
          <button type="button" onClick={zoomOut}>
            <FontAwesomeIcon icon={faMagnifyingGlassMinus} />
          </button>
          <button type="button" onClick={zoomIn}>
            <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
          </button>
          <a href={sheetURL} download={fileName}>
            <FontAwesomeIcon icon={faFileArrowDown} />
          </a>
          {/* <button type="button" onClick={() => printJS(sheetURL)}>
            <FontAwesomeIcon icon={faPrint} />
          </button> */}
        </>,
        document.getElementById('actions')
      )}
      <div id="progress-bar" />
      <div
        className="content"
        onMouseMove={mousePan}
        onWheel={scroll}
        onTouchStart={touchStart}
        onTouchMove={touchPan}
        onTouchCancel={touchEnd}
        onTouchCancelCapture={touchEnd}
        onTouchEnd={touchEnd}
        ref={contentRefCb}>
        <div style={{ transform: `translate(${offset.x}px,${offset.y}px)` }}>
          <Document
            file={sheet}
            onLoadSuccess={onLoadSuccess}
            className="document">
            {Array.from({ length: numPages }, (e, i) => (
              <Page
                className="page"
                key={i}
                pageNumber={i + 1}
                height={height / Math.SQRT2 < width ? height : undefined}
                width={width * Math.SQRT2 < height ? width : undefined}
                scale={scale}
                renderTextLayer={false}
              />
            ))}
          </Document>
        </div>
      </div>
      <div id="instrument-controls" />
    </div>
  );
};

export default SheetViewer;
