import { faCaretDown, faEye } from '@fortawesome/free-solid-svg-icons';
import './Songs.scss';
import { Searcher } from 'fast-fuzzy';
import React, { FC, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useFetch } from 'usehooks-ts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const Songs: FC = () => {
  const auth = useAuth();

  const mePlayerId = useFetch(
    `${process.env.REACT_APP_BACKEND_URL}/players/me`,
    {
      headers: {
        Authorization: `${auth.user.access_token}`
      }
    }
  ).data;

  const songs = useFetch<
    {
      nameSong: string;
      namePartitur?: string;
      partiturSheetId: string;
      voices: Array<{
        instrumentName: string;
        singleVoiceSheetId: string;
        players: string[];
      }>;
    }[]
  >(`${process.env.REACT_APP_BACKEND_URL}/songs`, {
    headers: {
      Authorization: `${auth.user.access_token}`
    }
  }).data;

  const [query, setQuery] = useState<string>();

  const filteredSongs = query
    ? new Searcher(songs, {
        keySelector: (voice) => (voice.namePartitur || '') + voice.nameSong
      }).search(query)
    : songs;
  return (
    <div className="Songs">
      <input
        type="text"
        value={query}
        onInput={(e) => setQuery((e.target as HTMLInputElement).value)}
      />
      <div className="songs-wrapper">
        {filteredSongs &&
          filteredSongs.map((song) => (
            <details key={song.namePartitur || song.nameSong} className="song">
              <summary>
                <div className="header">
                  <div className="info">
                    <h2>{song.nameSong}</h2>
                    {song.namePartitur && <h3>{song.namePartitur}</h3>}
                  </div>
                  <div className="icon-wrapper">
                    <FontAwesomeIcon icon={faCaretDown} />
                  </div>
                </div>
              </summary>
              <br />
              <Link to={`/partitur/${song.partiturSheetId}`} className="voice">
                <div>
                  <div className="instrument">Partitur</div>
                  <div className="players">
                    <p className="conductor">Dirigent*in</p>
                  </div>
                </div>
                <div className="icon-wrapper">
                  <FontAwesomeIcon icon={faEye} />
                </div>
              </Link>
              {song.voices.map((voice) => (
                <Link
                  to={`/einzelstimmen/${song.partiturSheetId}/${voice.singleVoiceSheetId}`}
                  className="voice"
                  key={voice.singleVoiceSheetId}>
                  <div>
                    <div className="instrument">{voice.instrumentName}</div>
                    <div className="players">
                      {voice.players.map((player) => (
                        <p key={player}>{player}</p>
                      ))}
                    </div>
                  </div>
                  <div className="icon-wrapper">
                    <FontAwesomeIcon icon={faEye} />
                  </div>
                </Link>
              ))}
            </details>
          ))}
      </div>
    </div>
  );
};

export default Songs;
