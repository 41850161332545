import React, { FC, useRef, useEffect, useState } from 'react';
import MidiPlayer from 'midi-player-js';
import {
  createSoundFont2SynthNode,
  type SoundFont2SynthNode
} from 'sf2-synth-audio-worklet';
import Slider from 'rc-slider';
import { useAuth } from 'react-oidc-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlay,
  faPause,
  faSliders,
  faStopwatch
} from '@fortawesome/free-solid-svg-icons';
import { useFetch, useMediaQuery } from 'usehooks-ts';
import useAnimationFrame from 'use-animation-frame';
import './Midi.scss';
import ReactDOM from 'react-dom';
import { clearTimeout } from 'timers';
import InstrumentControls from '../InstrumentControls/InstrumentControls';
import TempoControls from '../TempoControls/TempoControls';

interface MidiProps {
  partiturSheetId: string;
}

const Midi: FC<MidiProps> = (props) => {
  const auth = useAuth();

  const isDesktop = useMediaQuery('(pointer: fine)');

  const sounds = useFetch<{
    [key: string]: {
      bank: number;
      program: number;
    };
  }>(`${process.env.REACT_APP_BACKEND_URL}/sounds`, {
    headers: {
      Authorization: `${auth.user.access_token}`
    }
  });

  const [tracks, setTracks] = useState<
    {
      name: string;
      gain: number;
      preservedGain: number;
      partOfSolo: boolean;
    }[]
  >([]);
  const [playing, setPlaying] = useState(false);
  const [endOfFile, setEndOfFile] = useState(false);
  const [showInstrumentControls, setShowInstrumentControls] = useState(false);
  const [showTempoControls, setShowTempoControls] = useState(false);
  const showInstrumentControlsRef = useRef<HTMLButtonElement>();
  const showTempoControlsRef = useRef<HTMLButtonElement>();

  const midiPlayer = useRef(new MidiPlayer.Player());
  const synth = useRef<SoundFont2SynthNode>(undefined);
  const [t, setT] = useState<number>(0);
  const [tMax, setTMax] = useState<number>(0);
  const [tempo, setTempo] = useState<number>(undefined);
  const [initialTempo, setInitialTempo] = useState<number>(undefined);
  const playBufferTimeout = useRef<number>();

  if (tempo && tempo !== midiPlayer.current.tempo) {
    const wasPlaying = midiPlayer.current.isPlaying();
    if (wasPlaying) midiPlayer.current.pause();
    (midiPlayer.current as any).setTempo(tempo);
    if (wasPlaying) midiPlayer.current.play();
  }

  useAnimationFrame(() => {
    if (!midiPlayer.current) return;

    if (endOfFile) {
      setT((midiPlayer.current as any).totalTicks);
      return;
    }

    if (playing) setT(midiPlayer.current.getCurrentTick());
  });

  const handleMidiEvent = (e: any) => {
    if (e.name === 'Note on') {
      synth.current?.noteOn(
        e.track,
        e.noteNumber,
        Math.floor(tracks[e.track].gain * e.velocity),
        0
      );
    } else if (e.name === 'Note off') {
      synth.current?.noteOff(e.track, e.noteNumber, 0);
    }
  };

  const play = () => {
    if (!synth.current) {
      if (playBufferTimeout.current) clearTimeout(playBufferTimeout.current);
      playBufferTimeout.current = setTimeout(play, 500) as unknown as number;
      return;
    }
    (synth.current.context as AudioContext).resume();
    midiPlayer.current.play();
  };

  const pause = () => {
    midiPlayer.current.pause();
    setPlaying(false);
  };

  const soloInstrument = (i: number, add: boolean) => {
    setTracks((old) => {
      const noSoloInstruments: number =
        (old.map((e) => (e.partOfSolo ? 1 : 0)) as number[]).reduce(
          (acc, cur) => acc + cur
        ) + (add ? 1 : -1);
      old = old.map((track, j) => {
        if (add && noSoloInstruments === 1) {
          if (!track.partOfSolo) track.gain = 0;
        } else if (noSoloInstruments === 0) {
          track.gain = track.preservedGain;
        }
        return track;
      });
      old[i].partOfSolo = add;
      if (add) old[i].gain = old[i].preservedGain;
      else if (noSoloInstruments > 0) old[i].gain = 0;
      return old;
    });
  };

  (midiPlayer.current as any).eventListeners = {};
  midiPlayer.current.on('midiEvent', handleMidiEvent);
  midiPlayer.current.on('playing', () => setPlaying(true));
  midiPlayer.current.on('endOfFile', () => {
    setEndOfFile(true);
    setTimeout(() => setPlaying(false));
  });
  midiPlayer.current.on('fileLoaded', async () => {
    setTMax((midiPlayer.current as any).totalTicks);
    setTempo(midiPlayer.current.tempo);
    setInitialTempo(midiPlayer.current.tempo);
    for (const trackEvents of (midiPlayer.current as any).events) {
      for (const event of trackEvents) {
        if (event.name === 'Sequence/Track Name') {
          setTracks((old) => {
            const track = old[event.track] || {
              name: '',
              gain: 1,
              preservedGain: 1,
              partOfSolo: false
            };
            track.name = event.string;
            old[event.track] = track;
            return old;
          });
        }
      }
    }
    if (!sounds.data) {
      setTracks((old) => old);
      return null;
    }
    // synth.current setup
    const audioContext = new AudioContext();
    const url = '/iris.sf2';
    const node = await createSoundFont2SynthNode(audioContext, url);
    node.port.addEventListener('message', (ev) => {
      if (ev.data.type === 'wasm-module-loaded') synth.current = node;
    });
    node.connect(audioContext.destination);
    return null;
  });

  // synth.current configuration
  useEffect(() => {
    if (!synth.current) return;
    if (!sounds.data) return;
    if (!tracks) return;
    tracks.forEach((e, i) => {
      const sound = sounds.data[e.name];
      if (sound) {
        synth.current.setProgram(i, sound.bank, sound.program);
      }
    });
  }, [synth.current, sounds.data, tracks]);

  useEffect(() => {
    (async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/sheets/${props.partiturSheetId}/midi`,
        {
          headers: {
            Authorization: `${auth.user.access_token}`
          }
        }
      ).then((r) => r.arrayBuffer());
      midiPlayer.current.loadArrayBuffer(response);
    })();
  }, [sounds.data]);

  useEffect(() => {
    return () => {
      midiPlayer.current?.stop();
    };
  }, []);

  const onProgressInput = (e) => {
    if (endOfFile) setEndOfFile(false);
    const wasPlaying = midiPlayer.current.isPlaying();
    const tick = Number.parseInt(e, 10);
    if (wasPlaying) midiPlayer.current.pause();
    midiPlayer.current.skipToTick(tick);
    if (wasPlaying) midiPlayer.current.play();
    if (!wasPlaying) setT(tick);
  };

  if (showInstrumentControls || showTempoControls) {
    const offset = showInstrumentControlsRef.current.getBoundingClientRect();
    const instrumentControlsWrapperEl = document.getElementById(
      'instrument-controls'
    );
    if (matchMedia('(pointer: fine)').matches) {
      instrumentControlsWrapperEl.style.left = `${offset.x}px`;
    } else {
      instrumentControlsWrapperEl.style.left = '1rem';
      instrumentControlsWrapperEl.style.right = '1rem';
    }
    instrumentControlsWrapperEl.style.top = `${offset.y + offset.height}px`;
  }

  if (!sounds.data) return null;

  return (
    <div className="Midi" data-testid="Midi">
      {playing ? (
        <button type="button" onClick={pause}>
          <FontAwesomeIcon icon={faPause} />
        </button>
      ) : (
        <button type="button" onClick={play}>
          <FontAwesomeIcon icon={faPlay} />
        </button>
      )}
      <button
        id="show-tempo-controls"
        type="button"
        ref={showTempoControlsRef}
        onClick={() => {
          if (isDesktop) {
            setTempo(initialTempo);
          } else {
            setShowInstrumentControls(false);
            setShowTempoControls((old) => !old);
          }
        }}>
        <FontAwesomeIcon icon={faStopwatch} />
      </button>
      {isDesktop && tempo && initialTempo && (
        <div className="content">
          <TempoControls
            tempo={tempo}
            setTempo={setTempo}
            initialTempo={initialTempo}
          />
        </div>
      )}
      <button
        type="button"
        ref={showInstrumentControlsRef}
        onClick={() => {
          setShowInstrumentControls((old) => !old);
          setShowTempoControls(false);
        }}>
        <FontAwesomeIcon icon={faSliders} />
      </button>
      {ReactDOM.createPortal(
        <Slider min={0} max={tMax} value={t} onChange={onProgressInput} />,
        document.getElementById('progress-bar')
      )}
      {!isDesktop &&
        showTempoControls &&
        tempo &&
        initialTempo &&
        ReactDOM.createPortal(
          <>
            <div className="content">
              <TempoControls
                initialTempo={initialTempo}
                tempo={tempo}
                setTempo={setTempo}
              />
            </div>
            <div className="background-filter" />
          </>,
          document.getElementById('instrument-controls')
        )}
      {showInstrumentControls &&
        ReactDOM.createPortal(
          <>
            <div className="content">
              {tracks.map((track, i) => (
                <InstrumentControls
                  track={track}
                  updateGain={(gain) => {
                    setTracks((old) => {
                      const noSoloInstruments: number = (
                        old.map((e) => (e.partOfSolo ? 1 : 0)) as number[]
                      ).reduce((acc, cur) => acc + cur);
                      if (noSoloInstruments === 0 || old[i].partOfSolo)
                        old[i].gain = gain;
                      old[i].preservedGain = gain;
                      return old;
                    });
                  }}
                  solo={(add) => soloInstrument(i, add)}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`a${i}`}
                />
              ))}
            </div>
            <div className="background-filter" />
          </>,
          document.getElementById('instrument-controls')
        )}
    </div>
  );
};

export default Midi;
