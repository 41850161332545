// todo:
// * Füge zuletzt besuchte Seiten unten im Menü an.

import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import './MainMenu.scss';
import { useAuth } from 'react-oidc-context';

interface MainMenuProps {}

const MainMenu: FC<MainMenuProps> = () => {
  const auth = useAuth();
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const closeMenuOnMobile = () => {
    if (!matchMedia('(pointer: fine)').matches) {
      setShowMenu(false);
    }
  };

  return (
    <div
      className={`MainMenu ${showMenu ? 'open' : 'closed'}`}
      data-testid="MainMenu">
      <div id="actions" className="actions">
        <button type="button" onClick={() => setShowMenu((old) => !old)}>
          <FontAwesomeIcon icon={faBars} />
        </button>
      </div>
      <div className="menu">
        <div className="links">
          <Link to="/meine-noten" onClick={closeMenuOnMobile}>
            Meine Noten
          </Link>
          {auth.user.profile['cognito:groups'] &&
            (auth.user.profile['cognito:groups'] as any).includes('admin') && (
              <Link to="uebersicht" onAuxClick={closeMenuOnMobile}>
                Übersicht
              </Link>
            )}
        </div>
      </div>
      <div className="content">
        <Outlet />
      </div>
    </div>
  );
};

export default MainMenu;
