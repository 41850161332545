import React, { FC } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import './TempoControls.scss';

const { min, max } = Math;

interface TempoControlsProps {
  initialTempo: number;
  tempo: number;
  setTempo: (number) => void;
}

const TempoControls: FC<TempoControlsProps> = ({
  initialTempo,
  tempo,
  setTempo
}) => {
  const isDesktop = useMediaQuery('(pointer: fine)');

  const onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let n = Number.parseInt(e.currentTarget.value, 10);
    n = max(0.5 * initialTempo, n);
    n = min(n, 2 * initialTempo);
    setTempo(n);
  };

  return (
    <div className="TempoControls" data-testid="TempoControls">
      <input
        type="range"
        min={0.5 * initialTempo}
        max={2 * initialTempo}
        value={tempo}
        onInput={onInput}
      />
      {isDesktop && (
        <span>{`${Math.round((tempo / initialTempo) * 100)}%`}</span>
      )}
      {!isDesktop && (
        <div className="markers">
          <button type="button" onClick={() => setTempo(0.5 * initialTempo)}>
            0.5x
          </button>
          <button type="button" onClick={() => setTempo(initialTempo)}>
            1x
          </button>
          <button type="button" onClick={() => setTempo(2 * initialTempo)}>
            2x
          </button>
        </div>
      )}
    </div>
  );
};

export default TempoControls;
