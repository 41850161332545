import React, { FC } from 'react';
import { useAuth } from 'react-oidc-context';
import './Login.scss';

interface LoginProps {}

const Login: FC<LoginProps> = () => {
  const auth = useAuth();

  return (
    <div className="Login" data-testid="Login">
      <p>
        Du musst eingeloggt sein, um die Plattform nutzen zu können.
        <br />
        Falls du noch keinen Account hast, wende dich an Luis.
      </p>
      <button type="button" onClick={() => auth.signinRedirect()}>
        Log in
      </button>
    </div>
  );
};

export default Login;
